export const USER_ROLES = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
  USER: 'USER',
};

export const DEFAULT_USER_ROLES = [
  {
    key: 'ADMIN',
    value: 'ADMIN',
  },
  {
    key: 'USER',
    value: 'USER',
  },
];
